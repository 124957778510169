<template>
	<div class="container">
		<div class="query-title">
			<div class="demo-input-suffix" style="width: 100%;">
				<p class="titname">工种类型管理</p>
				<el-button type="primary" class="tit-button" @click="addBtn">新增菜单</el-button>
			</div>
		</div>
		<div class="table-container">
			<el-table :data="newMenulist" :height="tableHeight" @row-click="rowClick" highlight-current-row border style="width: 100%;" row-key="id" :tree-props="{children: 'jobList'}" :header-cell-style="{background:'rgba(113,183,255,0.4)',color:'#606266'}">
				<el-table-column type="index" align='center' width="55" label="序号"></el-table-column>
				<el-table-column prop="typeName" label="名称"></el-table-column>
				<el-table-column prop="createTime" label="创建时间"></el-table-column>
				<el-table-column prop="" label="操作" width="190" align="center">
					<template slot-scope="scope">
            <el-button class="tabBnt" v-if="scope.row.jobList" type="text" @click="addChild(scope.row,scope.$index)">添加子分类</el-button>
						<el-button class="tabBnt" type="text" v-if="scope.row.jobList" @click="edit(scope.row,scope.$index)">编辑</el-button>
						<el-button class="tabBnt" type="text" v-if="!scope.row.jobList" @click="childedit(scope.row,scope.$index)">编辑</el-button>
            <el-button class="tabBnt" type="text" v-if="scope.row.jobList" @click="deletes(scope.row,scope.$index)">删除</el-button>
            <el-button class="tabBnt" type="text" v-if="!scope.row.jobList" @click="childdeletes(scope.row,scope.$index)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<Dialog v-if="dialogVisible == true" :dialogdata="dialogData" @getcancel="Getcancel" @addetermine="addeteRmine"></Dialog>

    <childview v-if="childialog == true" :dialogdata="dialogData" @childgetcancel="childGetcancel" @childaddetermine="childaddeteRmine"></childview>
	</div>
</template>

<script>
	import Dialog from './dialogPopup.vue';
	import childview from './childview.vue';

	import { getTypeWorkListByPage,eTypeWorkdel,eJobTypedel} from "@/api/api";
	export default {
		name: 'typeofwork',
		components: {
			Dialog,
      childview
		},
		props: {},
		data() {
			return {
				dialogVisible: false,
        childialog:false,
				menuname: '',
				tableHeight: window.innerHeight - 188,
				newMenulist: [],
				dialogData: [],

			}
		},
		computed: {},
		watch: {},
		created() {
			window.addEventListener('resize', this.getHeight)
		},
		destroyed() {
			window.removeEventListener('resize', this.getHeight)
		},
		beforeDestroy() {},
		mounted() {

			this.menulist()
		},
		methods: {
			getHeight() {
				this.tableHeight = window.innerHeight - 188;
			},
			addBtn() {
				this.dialogVisible = true;
				this.dialogData = [];
			},
			edit(row, index) {
				this.dialogVisible = true;
				this.dialogData = [row, 'edit'];
			},
      childedit(row,index){
        this.childialog = true;
				this.dialogData = [row, 'edit'];
      },
      addChild(row,index){
          this.childialog = true;
          this.dialogData = [row];
      },
      childdeletes(row, index){
        	this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        		confirmButtonText: '确定',
        		cancelButtonText: '取消',
        		type: 'warning'
        	}).then(action => {
        		if(action === 'confirm') {
        			eJobTypedel({id:row.id}).then((res) => {
        				if(res.status == 200) {
        					this.menulist()
        					this.$message({
        						message: '删除成功',
        						type: 'success'
        					});
        				} else {
        					this.$message({
        						message: res.message,
        						type: 'success'
        					});
        				}
        			}).catch((err) => {
        				this.$message.error('删除失败！');

        			})
        		}
        	}).catch(() => {

        	});


      },
			deletes(row, index) {
				this.$confirm('此操作将永久删除, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(action => {
					if(action === 'confirm') {
						eTypeWorkdel({id:row.id}).then((res) => {

							if(res.status == 200) {
								this.menulist()
								this.$message({
									message: '删除成功',
									type: 'success'
								});
							} else {
								this.$message({
									message: res.message,
									type: 'success'
								});
							}
						}).catch((err) => {
							this.$message.error('删除失败！');

						})
					}
				}).catch(() => {

				});

			},

			rowClick(row) {
				this.row = row;
			},
      childaddeteRmine(){
        this.childialog = false;
        this.menulist()
      },
			addeteRmine() {
				this.dialogVisible = false;
				this.menulist()
			},
			Getcancel() {
				this.dialogVisible = false;
			},
      childGetcancel(){
        this.childialog = false;
      },

			menulist() {
				this.newMenulist = []
        let params={
          pageIndex:1,
          pageSize:1000,

        }
				getTypeWorkListByPage().then((res) => {
					if(res.status == 200) {

            this.newMenulist = res.data.records;
					} else {
						this.$message.error(res.message);
					}

				}).catch((error) => {
					this.$message.error('获取菜单失败！');
				})

			},


		}
	}
</script>

<style scoped>

</style>
